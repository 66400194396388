angular.module("app")
    .controller("bookingsCreateController",
        ["$rootScope", "$scope", "$log", "$q", "$location", "$window", "$document", "$state", "$filter", "placeToAddressTransformer", "landmarkToPlaceTransformer", "uiGmapGoogleMapApi", "bookingService", "timezoneService", "Notification", "staffService", "authenticatedStatus", function ($rootScope, $scope, $log, $q, $location, $window, $document, $state, $filter, placeToAddressTransformer, landmarkToPlaceTransformer, uiGmapGoogleMapApi, bookingService, timezoneService, Notification, staffService, authenticatedStatus) {
            var vm = this;
            vm.loading = false;
            vm.staff = undefined;
            vm.staffList = undefined;
            vm.landmarks = [];
            vm.vehicleTypes = [];
            vm.waitTime = undefined;
            vm.usePickupLocationNumber = false;
            vm.pickupAnywhere = false;
            vm.concierge = false;
            vm.pickupLocationContactNumber = undefined;
            vm.additionalInfoLength = 512;
            vm.pickupLocationInfo = undefined;
            vm.pickupLocationOrganisationLocationReference = undefined;
            vm.passengerFound = false;
            vm.creditCard = {
                cardNumber: undefined,
                cvv: undefined,
                expiryMonth: undefined,
                expiryYear: undefined
            };
            vm.paymentOptions = undefined;
            vm.capabilities = undefined;
            vm.fareErrorMessage = undefined;
            vm.fareError = false;
            vm.offsetMinutesPickupLocation = undefined;

            vm.routeOption = undefined;

            vm.routeOptions = undefined;

            vm.showMap = false;
            vm.formError = false;
            vm.getPaymentDisplay = getPaymentDisplay;
            vm.calculateFare = calculateFare;


            // Map porperties
            vm.map = {
                control: {},
                center: {},
                zoom: 15,
                refresh: true,
                options: {
                    draggable: true,
                    mapTypeControl: false,
                    scrollwheel: false,
                    streetViewControl: false,
                    overviewMapControl: false,
                    zoomControl: true
                },
                markers: {
                    hail: {
                        id: "1",
                        control: {},
                        coords: {},
                        options: {
                            clickable: false,
                            draggable: false,
                            zIndex: 500,
                            icon: '/static/images/mkr-hail.png',
                            visible: false
                        },
                        events: {
                            dragend: function (marker, eventName, args) {
                                $log.debug('marker dragend');
                                setPickupLocation(marker.getPosition().lat(), marker.getPosition().lng());
                            }
                        }
                    },
                    destination: {
                        id: "2",
                        control: {},
                        coords: {},
                        options: {
                            clickable: false,
                            draggable: false,
                            zIndex: 400,
                            icon: '/static/images/mkr-booking.png',
                            visible: false
                        },
                        events: {
                            dragend: function (marker, eventName, args) {
                                $log.debug('marker dragend');
                                setDestinationLocation(marker.getPosition().lat(), marker.getPosition().lng());
                            },
                            position_changed: function (marker, eventName, args) {
                                $log.debug("Position Changed");
                            },
                            visible_changed: function (marker) {
                                $log.debug("Visibility CHanged");
                            }

                        }
                    }
                },
                polylines: []
            };

            uiGmapGoogleMapApi.then(function (maps) {
                maps.visualRefresh = true;
            });

            vm.getStaffByName = function (name) {
                if (!_.isEmpty(name)) {
                    return loadStaff(name);
                }
            };


            vm.trip = {
                passenger: {
                    name: undefined,
                    mobileNumber: undefined,
                    passengerReference: undefined
                },
                pickupLocation: undefined,
                destinationLocation: undefined,
                requestedPickupDateTime: moment().add(5, 'minutes'),

                serviceType: "CAB",
                vehicleType: {vehicleType: "ANY"},
                vehicleOptionTypes: [],
                passengerTripPayment: undefined,
                paymentOption: undefined,
                additionalInfo: undefined // Note to driver
            };

            vm.autocompleteOptions = {
                componentRestrictions: {country: 'au'}
            };

            (function init() {
                bindLoaderStateEvents();
                // placeToAddressTransformer.from(null);

                setDefaultPickupLocation();
                setLandmarks();
                setConcierge();


            })();

            function bindLoaderStateEvents() {
                $scope.$on('loader_show', function () {
                    vm.loading = true;
                });

                $scope.$on('loader_hide', function () {
                    vm.loading = false;
                });
            }

            function loadStaff(name) {

                params = {
                    name: name,
                    page: 0,
                    includeInactive: false,
                    children: false
                };
                return staffService.listByName(params)
                    .then(function (results) {
                        return results.results;
                    })
                    ['finally'](function () {
                    vm.loadingStaff = false;
                });
            }

            vm.resetTrip = function () {

                vm.staff = undefined;

                resetPassenger();

                vm.trip.pickupLocation = undefined;
                vm.trip.destinationLocation = undefined;
                vm.trip.requestedPickupDateTime = moment().add(5, 'minutes');

                vm.trip.vehicleType = {vehicleType: "ANY"};
                vm.trip.additionalInfo = undefined;

                vm.vehicleTypes = [];
                vm.trip.vehicleOptionTypes = [];
                vm.trip.passengerTripPayment = undefined;
                vm.offsetMinutesPickupLocation = undefined;
            };

            $scope.$watch('ctrl.staff', function (staff) {
                if (_.isEmpty(staff)) {
                    vm.trip.passenger.name = undefined;
                    vm.trip.passenger.mobileNumber = undefined;
                    vm.trip.passenger.passengerReference = undefined;
                } else {
                    vm.trip.passenger.name = staff.fullName;
                    vm.trip.passenger.mobileNumber = staff.mobileNumber;
                    vm.trip.passenger.passengerReference = staff.accountReference;
                }
                vm.trip.paymentOption = undefined;
                calculateFare();
            });

            $scope.$watch('ctrl.trip.requestedPickupDateTime', function (newValue, oldValue) {
                $scope.bookingForm.$setValidity("isInPast", !vm.isTimeInPast());
            });

            vm.useMyNumber = function () {
                resetPassenger();
                vm.usePickupLocationNumber = !vm.usePickupLocationNumber;
            };

            vm.passengerDetailsChanged = function () {

                // If the user changes the passenger details, we can assume that it's not a staff member, so remove the reference.
                if (vm.staff) {
                    if (!(vm.trip.passenger.name === vm.staff.funllname && vm.trip.passenger.mobileNumber === vm.staff.mobileNumber)) {

                        vm.staff = undefined;
                        vm.trip.passenger.passengerReference = undefined;
                        $log.debug('Staff Member Reset');
                    }
                } else {
                    // lets look up the mobile number to see if there is a passenger assoicated
                    if ($scope.bookingForm.mobileNumber.$valid && vm.trip.passenger.mobileNumber !== '') {

                        bookingService.findPassenger(vm.trip.passenger.mobileNumber)
                            .then(function (passenger) {

                                if (passenger === null) {
                                    vm.passengerFound = false;
                                } else {
                                    vm.passengerFound = true;
                                    vm.trip.passenger = passenger;
                                }
                            });
                    }

                }

            };

            vm.isTimeInPast = function () {
                if (!vm.trip.requestedPickupDateTime) {
                    return false;
                }
                var pickupTime = moment(vm.trip.requestedPickupDateTime).format('YYYY-MM-DD[T]HH:mm:ss' + vm.offsetMinutesPickupLocation);
                var nowOffset = moment().utcOffset(vm.offsetMinutesPickupLocation);

                return moment(new Date(pickupTime)).isBefore(nowOffset);

            };


            function setDefaultPickupLocation() {
                bookingService.getDefaultLocation()
                    .then(function (location) {
                        if (location) {
                            var place = placeToAddressTransformer.to(location);
                            vm.trip.pickupLocation = place;
                            vm.trip.gpsPosition = {};
                            vm.trip.gpsPosition.latitude = place.geometry.location.lat();
                            vm.trip.gpsPosition.longitude = place.geometry.location.lng();

                            vm.pickupLocationContactNumber = location.contactNumber;

                            // Need to do the same for location info as location contact number
                            if (location.info) {
                                vm.pickupLocationInfo = location.info;
                                vm.additionalInfoLength = vm.additionalInfoLength - vm.pickupLocationInfo.length - 3;
                            }
                            vm.pickupLocationOrganisationLocationReference = location.organisationLocationReference;
                        }
                    }, function (error) {
                        $log.error('error: ' + error);
                        Notification.error({message: error.data, delay: 5000});
                    })
                    ['finally'](function () {
                    vm.loading = false;
                });
            }

            function resetPassenger() {
                vm.trip.passenger.name = undefined;
                vm.trip.passenger.mobileNumber = undefined;
                vm.trip.passenger.passengerReference = undefined;

                vm.passengerFound = false;
            }


            function setConcierge() {
                isConciergeRole().then(function (result) {
                    vm.pickupAnywhere = !result;
                    vm.concierge = result;
                });
            }

            function isConciergeRole() {
                return authenticatedStatus.user.promise.then(function (user) {
                    return _.includes(user.roles, "BUSINESS_CONCIERGE");
                });
                // return _.includes(authenticatedStatus.directUser.roles, "BUSINESS_CONCIERGE");
            }

            function setMarkerPin(location, marker) {

                vm.showMap = true;

                if (!haveBothLocations()) {
                    vm.map.center = location;
                }

                marker.options.visible = true;
                marker.coords = location;

            }


            function setLandmarks() {
                bookingService.getLandmarks()
                    .then(function (landmarks) {

                        if (landmarks) {
                            _.each(landmarks, function (landmark) {
                                vm.landmarks.push(landmarkToPlaceTransformer.from(landmark));
                            });
                        }
                    }, function (error) {
                        $log.error('error: ' + error);
                        Notification.error({message: error.data, delay: 5000});
                    })
                    ['finally'](function () {
                    vm.loading = false;
                });
            }

            vm.showIconVehicleType = function (object) {
                var vehicleType = object.vehicleType;
                var returningClassName = vehicleType + "-icon";
                if (vm.trip.vehicleType.vehicleType == vehicleType) {
                    returningClassName += "-dark";
                }
                return returningClassName;
            };

            vm.showIconCapabilities = function (object) {
                var returningClassName = object.optionType + "-icon";
                if (vm.trip.vehicleOptionTypes.indexOf(object) > -1) {
                    returningClassName += "-dark";
                }
                return returningClassName;
            };

            $scope.$watch('ctrl.trip.paymentOption', function (newNames, oldNames) {
                if (!vm.trip.paymentOption && vm.paymentOptions && vm.paymentOptions.length > 0) {
                    var paymentOption = vm.paymentOptions[0];
                    vm.trip.paymentOption = {
                        totalFare: paymentOption.totalFare
                    };
                }
            }, true);

            function fitBounds() {
                if (haveBothLocations()) {
                    var bounds = new google.maps.LatLngBounds();
                    _.forEach(vm.map.markers, function (marker) {
                        bounds.extend(new google.maps.LatLng(marker.coords.latitude, marker.coords.longitude));
                    });
                    var center = bounds.getCenter();
                    vm.map.center = {
                        latitude: center.lat(),
                        longitude: center.lng()
                    };
                    vm.map.control.getGMap().fitBounds(bounds);
                }
            }

            function getLatLng(place) {
                if (_.isEmpty(place)) return {};
                return {
                    latitude: place.geometry.location.lat(),
                    longitude: place.geometry.location.lng()
                };
            }

            function haveBothLocations() {
                return angular.isObject(vm.trip.pickupLocation) && angular.isObject(vm.trip.destinationLocation);
            }

            $scope.$watch('ctrl.trip.pickupLocation', function (newNames, oldNames) {
                if (angular.isObject(vm.trip.pickupLocation)) {
                    setMarkerPin(getLatLng(vm.trip.pickupLocation), vm.map.markers.hail);
                    fitBounds();
                }
            }, true);

            $scope.$watch('ctrl.trip.destinationLocation', function (newNames, oldNames) {
                if (angular.isObject(vm.trip.destinationLocation)) {
                    setMarkerPin(getLatLng(vm.trip.destinationLocation), vm.map.markers.destination);
                    fitBounds();
                }
            }, true);

            $scope.$watchCollection('[ctrl.trip.pickupLocation, ctrl.trip.destinationLocation, ctrl.trip.requestedPickupDateTime, ctrl.staff, ctrl.trip.vehicleType.vehicleType]', function (newValues) {
                calculateFare();
            });

            $scope.$watch('ctrl.trip.vehicleOptionTypes', function (newNames) {
                if (!_.isUndefined(newNames)) {
                    $log.debug("calling from watched vehcileOptionTypes");
                    $log.debug(newNames);
                    calculateFare();
                }
            }, true);

            function calculateFare() {

                vm.paymentOptions = undefined;
                vm.routeOptions = [];
                vm.map.polylines = [];
                vm.routeOption = undefined;


                if (haveBothLocations() && !vm.isTimeInPast()) {

                    vm.loading = true;
                    $log.debug("Fetching Fare");

                    var trip = angular.copy(vm.trip);

                    // vm.paymentOptions = undefined;

                    var locality = getLocality(vm.trip.destinationLocation);


                    trip.destinationLocation = placeToAddressTransformer.from(vm.trip.destinationLocation, $scope.bookingForm.destinationLocation);
                    trip.pickupLocation = placeToAddressTransformer.from(vm.trip.pickupLocation, $scope.bookingForm.pickupLocation);
                    trip.pickupLocation.info = vm.pickupLocationInfo;
                    trip.pickupLocation.organisationLocationReference = vm.pickupLocationOrganisationLocationReference;
                    if (vm.usePickupLocationNumber) {
                        trip.pickupLocation.contactNumber = vm.pickupLocationContactNumber;
                    }
                    if (vm.staff) {
                        trip.passenger.name = vm.staff.fullName;
                        trip.passenger.mobileNumber = vm.staff.mobileNumber;
                        trip.passenger.passengerReference = vm.staff.accountReference;
                    }

                    trip.vehicleType = vm.trip.vehicleType.vehicleType;

                    adjustTimezone(locality, vm.trip.requestedPickupDateTime).then(function (pickupDateTime) {

                            trip.requestedPickupDateTime = pickupDateTime;

                            bookingService.getFare(trip).then(
                                function (returnedTrip) {

                                    vm.fareError = false;
                                    vm.fareErrorMessage = undefined;

                                    vm.routeOptions = returnedTrip.data.routeOptions;

                                    vm.routeOption = findRecommendedRoute(vm.routeOptions);

                                    setPaymentOptions(vm.routeOption.paymentOptions);

                                    drawRouteOptions();

                                    vm.waitTime = returnedTrip.data.availableServices[0].waitTime;

                                    if (!vm.capabilities) {
                                        vm.vehicleTypes = returnedTrip.data.availableServices[0].vehicleTypes;
                                        vm.trip.vehicleType = vm.vehicleTypes[0];
                                        vm.capabilities = vm.vehicleTypes[0].options;
                                    }
                                },
                                function (error) {
                                    $log.error("error: " + error);
                                    Notification.error({
                                        message: error.data,
                                        delay: 5000
                                    });
                                    vm.fareErrorMessage = getFareErrorMessage(error.data);
                                    vm.fareError = true;
                                }
                            )['finally'](function () {
                                vm.loading = false;
                            });
                        },
                        function (error) {
                            $log.error(error);
                        }
                    );
                }
            }

            function getFareErrorMessage(error) {
                if (error.indexOf('Request to ingogo api failed') > -1) {
                    return 'Unable to calculate fare';
                }
                return error;
            }

            function findRecommendedRoute(routeOptions) {
                var routeOption = _.find(routeOptions, function (routeOption) {
                    return routeOption.type === "RECOMMENDED";
                });

                return routeOption;
            }

            function drawRouteOptions() {

                vm.map.polylines = [];

                var defaultPath;

                var index = 0;

                var bounds = new google.maps.LatLngBounds();

                _.forEach(vm.routeOptions, function (routeOption) {
                    var encodedPolyline = routeOption.routeSegments[0].path;
                    if (encodedPolyline) {
                        var decodedPath = google.maps.geometry.encoding.decodePath(encodedPolyline);

                        _.forEach(decodedPath, function (bound) {
                            bounds.extend(bound);
                        });

                        if (routeOption.type === vm.routeOption.type) {
                            defaultPath = decodedPath;
                        } else {
                            drawPolylines(decodedPath, '#B0BEC5', 8, routeOption);
                        }
                    }
                    index++;
                });

                drawPolylines(defaultPath, '#130BD7', 8, vm.routeOption);
                drawPolylines(defaultPath, '#2999FA', 4, vm.routeOption);

                vm.map.control.getGMap().fitBounds(bounds);
            }

            function drawPolylines(decodedPath, color, weight, routeOption) {

                var latLngIndex = Math.round(decodedPath.length / 2);
                var index = _.indexOf(vm.routeOptions, routeOption);

                if (vm.routeOptions.length > 1 && routeOption !== vm.routeOption) {
                    if (index === 0) latLngIndex = Math.round(latLngIndex / 2);
                    if (index > 0) latLngIndex = Math.round(latLngIndex / 2) + latLngIndex;
                }

                var styleClass = routeOption === vm.routeOption ? 'selectedLabelClass' : 'labelClass';

                var travelTime = moment.duration(routeOption.duration);
                var defaultTravelTime = moment.duration(vm.routeOption.duration);


                vm.map.polylines.push({
                    path: decodedPath,
                    stroke: {
                        color: color,
                        weight: weight,
                    },
                    static: true,
                    clickable: true,
                    marker: {
                        id: routeOption.type,
                        coords: {latitude: decodedPath[latLngIndex].lat(), longitude: decodedPath[latLngIndex].lng()},
                        options: {
                            icon: '/static/images/spacer.png',
                            labelContent: $filter('currency')(routeOption.paymentOptions[0].totalFare) + '</br>' + moment.duration(routeOption.duration).asMinutes() + ' mins',
                            labelAnchor: '25 27',
                            labelClass: styleClass,
                            labelInBackground: false,
                            visible: true
                        },
                        events: {
                            click: function (event) {
                                selectRoute(routeOption);
                            }
                        }
                    },
                    events: {
                        click: function (event) {
                            selectRoute(routeOption);
                        }
                    }
                });
            }

            function selectRoute(routeOption) {
                if (routeOption === vm.routeOption) return;
                vm.routeOption = routeOption;
                drawRouteOptions();
                setPaymentOptions(vm.routeOption.paymentOptions);
            }

            function setPaymentOptions(paymentOptions) {

                paymentOptions = _.sortBy(paymentOptions, function (paymentOption) {
                    return paymentOption.paymentMethodReference;
                });

                if (vm.concierge || _.isUndefined(vm.trip.passenger.passengerReference)) {
                    paymentOptions = _.filter(paymentOptions, function (paymentOption) {
                        return _.isUndefined(paymentOption.paymentMethodReference);
                    });
                    paymentOptions.reverse();
                }

                if (_.isUndefined(vm.trip.paymentOption)) {
                    vm.trip.paymentOption = paymentOptions[0];
                } else {
                    vm.trip.paymentOption = _.find(paymentOptions, function (paymentOption) {
                        return paymentOption.type === vm.trip.paymentOption.type;
                    });
                }

                vm.paymentOptions = paymentOptions;

            }

            function getPaymentDisplay(paymentOption) {

                if (_.isUndefined(paymentOption)) return '';

                var display = paymentOption.displayName;

                switch (paymentOption.type) {
                    case 'OTHER':
                        display = 'Pay Driver';
                        break;
                    case 'SINGLE_USE_CARD':
                        display = 'Pre-Pay Now';
                        break;
                    case 'CARD':
                        display = 'Pre-Pay Now';
                        break;
                }

                return display;
            }

            vm.getPaymentOptionClass = function (paymentOption) {
                var cssClass = 'account-icon';

                switch (paymentOption.type) {
                    case 'CARD':
                        cssClass = 'card-icon';
                        break;
                    case 'CASH':
                        cssClass = 'cash-icon';
                        break;
                    case 'AMEX':
                        cssClass = 'amex-icon';
                        break;
                    default:
                    // something;
                }
            };

            vm.makeBooking = function () {
                vm.loading = true;
                vm.formError = false;

                if (vm.fareError) return;

                angular.forEach($scope.bookingForm.$error.required, function (field) {
                    field.$setTouched();
                });

                $scope.bookingForm.$setValidity("isInPast", !vm.isTimeInPast());

                if ($scope.bookingForm.$valid) {
                    $log.debug('Making Booking');

                    var trip = angular.copy(vm.trip);

                    var locality = getLocality(vm.trip.destinationLocation);

                    trip.pickupLocation = placeToAddressTransformer.from(vm.trip.pickupLocation, $scope.bookingForm.pickupLocation);
                    trip.pickupLocation.info = vm.pickupLocationInfo;
                    trip.pickupLocation.organisationLocationReference = vm.pickupLocationOrganisationLocationReference;
                    trip.destinationLocation = placeToAddressTransformer.from(vm.trip.destinationLocation, $scope.bookingForm.destinationLocation);
                    if (vm.usePickupLocationNumber) {
                        trip.pickupLocation.contactNumber = vm.pickupLocationContactNumber;
                    }

                    if (vm.trip.paymentOption.type === 'CARD' || vm.trip.paymentOption.type === 'SINGLE_USE_CARD') {
                        var creditCard = angular.copy(vm.creditCard);
                        creditCard.expiryYear = creditCard.expiryYear.toString().substr(2);
                        trip.passengerTripPayment =
                            {
                                "fareReference": vm.trip.paymentOption.fareReference,
                                "type": vm.trip.paymentOption.type,
                                "fareType": vm.trip.paymentOption.fareType,
                                "cardDetails": creditCard
                            };
                    } else {
                        trip.passengerTripPayment = {
                            "fareReference": vm.trip.paymentOption.fareReference,
                            "paymentMethodReference": vm.trip.paymentOption.paymentMethodReference,
                            "type": vm.trip.paymentOption.type,
                            "fareType": vm.trip.paymentOption.fareType
                        };
                    }

                    trip.vehicleType = 'ANY';

                    if (!_.isUndefined(vm.trip.vehicleType)) {
                        if (!_.isUndefined(vm.trip.vehicleType.vehicleType)) {
                            trip.vehicleType = vm.trip.vehicleType.vehicleType;
                        }
                    }

                    adjustTimezone(locality, vm.trip.requestedPickupDateTime).then(function (pickupDateTime) {

                        trip.requestedPickupDateTime = pickupDateTime;

                        bookingService.create(trip)
                            .then(function (trip) {

                                Notification.info({message: 'Your trip has been successfully booked', delay: 5000});

                                vm.resetTrip();

                                $state.go("bookings");

                            }, function (error) {
                                $log.error('error: ' + error);
                                Notification.error({message: error.data, delay: 5000});
                            })
                            ['finally'](function () {
                            vm.loading = false;
                        });
                    }, function (error) {
                        $log.error(error);
                    });

                }
                else {
                    $log.debug('Fix errors');
                    vm.formError = true;
                    Notification.error({message: "Please correct errors", delay: 5000});
                }
            };

            function getLatitudeFromGeoResult(result) {
                return (result) ? result.geometry.location.lat() : null;
            }

            function getLongitudeFromGeoResult(result) {
                return (result) ? result.geometry.location.lng() : null;
            }

            function getLocality(pickupLocation) {
                if (!_.isUndefined(pickupLocation) && !_.isUndefined(pickupLocation.geometry)) {
                    return {
                        latitude: getLatitudeFromGeoResult(pickupLocation),
                        longitude: getLongitudeFromGeoResult(pickupLocation)
                    };
                }

                return undefined;
            }

            function adjustTimezone(locality, pickupDateTime) {

                var defer = $q.defer();

                if (_.isUndefined(pickupDateTime)) {
                    //this sets the time to now with local timezone
                    pickupDateTime = moment();
                }

                timezoneService.timezone(locality, pickupDateTime)
                    .then(function (response) {
                        // Google API and javascript have inverse representations of time zone offsets
                        var offsetMins = (response.data.rawOffset + response.data.dstOffset) / 60;
                        var offsetInPickupLocation = pickupDateTime.clone().utcOffset(offsetMins).format('Z');
                        vm.offsetMinutesPickupLocation = offsetInPickupLocation;

                        // only consider changing offset of pickup time when it is a booking for now
                        if (!vm.trip.requestedPickupDateTime) {
                            pickupDateTime = pickupDateTime.clone().utcOffset(offsetMins);
                        }

                        defer.resolve(pickupDateTime.format('YYYY-MM-DD[T]HH:mm:ss' + offsetInPickupLocation));

                    }, function (error) {
                        $scope.bookingForm.requestedPickupDateTime.$setTouched();
                        $scope.bookingForm.requestedPickupDateTime.$setValidity('locality', false);
                        defer.reject("Could not get timezone");
                    });

                return defer.promise;
            }
        }])
;
